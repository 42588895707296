@-moz-document url-prefix() {
    .mat-form-field-prefix,
    .mat-datepicker-toggle-default-icon,
    .mat-form-field-suffix {
        top: -16px;
    }
    .mat-form-field {
        margin-top: 48px;
        height: 52px;
    }
    .separated-title {
        padding-bottom: 10px;
    }
}
