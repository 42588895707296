@import "./variables";

@media (max-width: 767px) {
    .separator {
        border-right: none;
    }
    .w100mobile {
        width: 100%;
    }
    .mt-2-mobile {
        margin-top: 0.5rem !important;
    }
    .mt-3-mobile {
        margin-top: 0.75rem !important;
    }
    .contact-combobox {
        .mat-radio-label {
            min-width: auto;
        }
    }
    .modes .mode {
        width: 100% !important;
    }
    .container {
        max-width: 99%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .contact-combobox {
        .mat-radio-label {
            min-width: 260px;
        }
    }
    .container {
        max-width: 99%;
    }
}

@media (min-width: 992px) {
    .contact-combobox {
        .mat-radio-label {
            min-width: 300px;
        }
    }
    .container {
        max-width: 98%;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1400px;
    }
}
