$numbers: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

$text-color: #2e3745;
$link-color: #0000ff;
$background-color: #f4f7fa;
$pink-color: #2f45c5;
$pink-hover-color: #001dc5;
$pink-bg-hover-color: #f7f8ff;
$light-pink-color: #438ffe1a;
$menu-text-color: #1b2648;
$border-color: #dbe6f0;
$input-border: #dbe6f0;
$color-option-bg-hover: #f5f6f7;
$error-color: #f51837;
$red-text-color: #e65214;
$green-color: #bece08;
$grey-text-color: #4d4d4d;
$form-control-text-color: #555;

$blue-btn-color: #189bd5;
$blue-btn-hover-color: #117099;

$disabled-background: #e5e5e5;
$disabled-input-background: #eee;
$disabled-text-color: #8b9abd;

$background-tooltip-color: #f2f2f2;

$line-color: rgba(0, 0, 0, 0.125);
$help-block-text-color: #767676;
$error-color: #f51837;
$red-text-color: #e65214;
$green-color: #28a745;
$green-color-hover: #1e7e34;
$blue-color: #007bff;
$blue-color-hover: #0062cc;
$grey-text: #4d4d4d;
$bg-not-active: #f8f9fa;

$xl-size: 1199px;
