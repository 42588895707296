@import "./variables";

.p-tooltip {
    position: absolute !important;
    &.tooltip-lg {
        .p-tooltip-text {
            width: 700px;
        }
    }
    &.no-bg {
        .p-tooltip-text {
            background-color: transparent !important;
            border: none;
            text-align: center;
        }
    }
    .p-tooltip-text {
        background: $background-tooltip-color !important;
        color: $grey-text-color !important;
        width: 320px;
        border-radius: 3px;
        border: 1px solid $input-border;
        padding: 5px 10px;
        font-size: 14px;
        margin-left: 5px;
        .tooltip-header {
            color: $text-color;
            font-weight: bold;
        }
    }
}

@media (max-width: 575px) {
    .p-tooltip {
        width: 100%;
        left: 0 !important;
        margin-top: -70px !important;
        .p-tooltip-text {
            width: 100%;
            padding: 0 10px;
            margin-left: 0;
        }
    }
}
