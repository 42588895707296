@import "variables";

.mat-slide-toggle {
    margin: 10px 0;
    margin-left: 4px;
    display: inline-block;
    height: 24px;
    max-width: 100%;
    line-height: 24px;
    white-space: nowrap;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    .mat-slide-toggle-label {
        -webkit-user-select: none;
        user-select: none;
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        height: inherit;
        cursor: pointer;
        ._mat-animation-noopable .mat-slide-toggle-bar,
        ._mat-animation-noopable .mat-slide-toggle-thumb {
            transition: none;
        }
        .mat-slide-toggle-bar {
            position: relative;
            width: 36px;
            height: 14px;
            flex-shrink: 0;
            border-radius: 8px;
            .mat-slide-toggle-thumb-container {
                position: absolute;
                z-index: 1;
                width: 20px;
                height: 20px;
                top: -3px;
                left: 0;
                transform: translate3d(0, 0, 0);
                transition: all 80ms linear;
                transition-property: transform;
                .mat-slide-toggle-thumb {
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    display: block;
                    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
                        0px 1px 3px 0px rgb(0 0 0 / 12%);
                    background-color: #fafafa;
                }
                .mat-slide-toggle .mat-slide-toggle-ripple {
                    position: absolute;
                    top: calc(50% - 20px);
                    left: calc(50% - 20px);
                    height: 40px;
                    width: 40px;
                    z-index: 1;
                    pointer-events: none;
                }
                .mat-ripple:not(:empty) {
                    transform: translateZ(0);
                }
                .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
                    background-color: #000;
                }
                .mat-slide-toggle-persistent-ripple {
                    width: 100%;
                    height: 100%;
                    transform: none;
                }
            }
        }
        [dir="rtl"] .mat-slide-toggle-label-before .mat-slide-toggle-bar,
        .mat-slide-toggle-bar {
            margin-right: 8px;
            margin-left: 0;
        }
        .mat-slide-toggle-bar {
            background-color: rgba(0, 0, 0, 0.38);
            .mat-slide-toggle-input {
                bottom: 0;
                left: 10px;
            }
        }
    }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(16px, 0, 0) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $pink-color !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-label .mat-slide-toggle-bar {
    background-color: $light-pink-color !important;
}

.cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    left: 0;
}

.mat-slide-toggle-content {
    margin: 10px 0;
    white-space: break-spaces !important;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    display: inline-block;
    line-height: 24px;
    padding-right: 0px;
    font-size: 13px;
    font-weight: 600;
}