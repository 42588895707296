@import "variables";

.form-row {
    position: relative;
    .error-msg {
        position: absolute;
        text-align: left;
        right: 0;
        left: 100%;
        width: 100%;
        padding-left: 10px;
        vertical-align: middle;
        font-style: italic;
        color: $error-color;
        font-size: 0.722rem;
    }
}

label {
    margin-bottom: 0;
    width: 100%;
    text-align: left;
}

.radio-label {
    min-height: 50px;
    min-width: 98px;
    color: white;
    border-radius: 8px !important;
    transition: 0.5s ease all;
    cursor: pointer;
    vertical-align: middle;
    padding: 0.7rem 1.5rem;
    position: relative;
    background: $text-color;
    border-color: $text-color;
    input[type="radio"] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    &:focus {
        box-shadow: none;
    }
}
.radio-label.civility-btn {
    width: 6.5rem;
    padding: 1rem;
}

.form-group {
    margin-top: 0;
    margin-bottom: 0;
}

.input-block {
    margin-bottom: 1.389rem;
    input[type="text"],
    input[type="number"] {
        display: block;
        width: 100%;
        min-height: 3rem;
        padding: 0.7rem 1.1rem;
        font-size: 15px;
        line-height: 1rem;
        color: #555;
        background-color: white;
        background-image: none;
        border: 1px solid $input-border;
        border-radius: 3px;
    }
    input[type="checkbox"] {
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
    }
    .medium-inputs input {
        width: 100%;
    }
    .birth-date {
        input {
            width: 21%;
            margin-right: 3%;
            text-align: center;
            display: inline-block;
            padding: 0.889rem 0.556rem;
        }
        input.form-control.birth-year {
            width: 52%;
            margin-right: 0;
        }
    }
}

.check-mark {
    position: absolute;
    top: 0.3rem;
    right: -3rem;
    background-color: $green-color;
    width: 2rem;
    height: 2rem;
    padding: 7px 9px;
    border-radius: 50%;
    font-size: 1rem;
    color: white;
    &.question {
        right: -20px;
        top: 25%;
    }
}

.error {
    background-color: $error-color;
    padding: 6px 11px;
}

.active-btn {
    background: $green-color !important;
    border-color: $green-color !important;
}

.error-btn {
    background: $error-color;
    border-color: $error-color;
    &:focus {
        background: $error-color;
        border-color: $error-color;
    }
}
.custom-select {
    min-height: 52px;
    font-size: 14px;
    line-height: 18px;
    color: #555;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
}

button.validation-button {
    background: $text-color;
    min-height: 48px;
    border-radius: 8px;
    color: white;
    width: 34%;
    transition: 0.5s ease all;
    cursor: pointer;
    line-height: 1rem;
    margin: 0;
    vertical-align: middle;
    padding: 0.5rem 1.5rem;
    border-color: $text-color;
    &:disabled {
        cursor: not-allowed;
    }
}

.small-btn {
    width: 5rem;
}

.red-link {
    cursor: pointer;
    color: $red-text-color;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
        color: $red-text-color;
    }
}

.radio-group-checkboxes {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
}

.inline {
    .radio-group-checkboxes {
        display: inline;
    }
}

.radio-button-checkboxes {
    margin: 11px 5px;
    .mat-radio-label {
        margin-bottom: 0;
        white-space: inherit;
    }
    .mat-radio-outer-circle {
        border-width: 1px;
        color: $input-border;
        background-color: white;
    }
    .mat-radio-inner-circle {
        background-color: $pink-color;
    }
    .mat-radio-label-content {
        color: $text-color;
    }
    .cdk-visually-hidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        white-space: nowrap;
        outline: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
}
