@import "./variables";

.file-uploader {
    file-upload {
        background: none;
        outline: none;
        padding: 0;
        min-height: 50px;
        display: inline-block !important;
        &.ng-touched.ng-invalid {
            .upload-text {
                // border: 1px solid $error-color;
            }
        }
        .upload-input {
            cursor: pointer;
            display: flex;
            position: initial;
            transform: none;
            outline: none;
            width: 100%;
            text-align: center;
            font-size: 13px;
            file-upload-drop-zone {
                .icon {
                    display: none;
                }
                .upload-text {
                    transition: 0.5s ease all;
                    cursor: pointer;
                    line-height: 1.5;
                    border-radius: 0.25rem;
                    font-size: 14px;
                    font-weight: 600;
                    padding: 7px 20px;
                    border: 1px solid #2f45c5;
                    color: #2f45c5;
                    border: 1px solid $pink-color;
                    color: $pink-color;
                    &:hover:not(:disabled) {
                        background-color: #f7f8ff;
                    }
                    &:disabled {
                        border: 1px solid $border-color;
                        color: $text-color;
                    }
                    img {
                        height: 12px;
                        padding-right: 3px;
                    }
                }
            }
        }
    }
    .file-list {
        color: $text-color;
        font-size: 13px;
        padding-left: 20px;
        .file-item {
            display: table;
            cursor: pointer;
            overflow-wrap: anywhere;
            .delete-icon {
                color: $error-color;
                position: absolute;
                left: 20px;
            }
        }
    }
    .error-list {
        font-size: 13px;
        color: $error-color;
    }
}

.in-table-cell {
    .file-uploader {
        .upload-input {
            width: auto;
            margin-left: 0;
            .upload-text {
                padding: 9px 4px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 959px) {
    .file-uploader {
        file-upload {
            .upload-input {
                margin-left: 0px;
                width: 100%;
            }
        }
    }
}
