@import "./variables";

.text-underline {
    text-decoration: underline;
}

.text-italic {
    font-style: italic;
}

.label-like {
    color: $text-color;
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
}

.red-text {
    color: $red-text-color;
}

.link-like {
    text-decoration: underline;
    color: $link-color;
    &:hover {
        cursor: pointer;
        text-decoration: none !important;
    }
}

.pink {
    color: $pink-color;
}

h6,
h5,
h4,
h3,
h2,
h1 {
    margin: 0;
}

h5,
.h5 {
    line-height: 1.35rem;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}
