@import "./variables";

.dataTable {
    .dataTables_processing {
        display: none !important;
    }
    border-bottom: none;
    &.no-footer {
        border-bottom: 1px solid $border-color !important;
    }
    .dataTables_filter,
    .dataTables_empty {
        display: none;
    }

    .no-footer {
        border: none;
    }

    .select-column {
        text-align: center;
        input {
            cursor: pointer;
        }
    }

    .dataTables_length {
        position: absolute;
        bottom: 4px;
        left: 40%;
        select {
            border: none !important;
            &:focus-visible {
                outline: none;
            }
        }
    }

    tbody tr {
        background-color: white !important;
    }

    thead th,
    thead td,
    .row-border tbody th,
    .row-border tbody td,
    .display tbody th,
    .display tbody td {
        border-top: 1px solid $border-color !important;
        border-bottom: none !important;
        border-right: none;
        border-left: 1px solid $border-color !important;
        &:last-of-type {
            border-right: 1px solid $border-color !important;
        }
    }

    .dataTables_wrapper {
        .dataTables_paginate {
            .paginate_button {
                background: white !important;
                border: 1px solid $pink-color !important;
                color: $text-color !important;
                &:not(.disabled):hover {
                    background-color: #f7f8ff !important;
                    color: $text-color !important;
                }
                &.disabled {
                    border: 1px solid $border-color !important;
                    color: $disabled-text-color !important;
                    background: none !important;
                    &:hover {
                        color: $disabled-text-color !important;
                    }
                }
                &:active {
                    box-shadow: none !important;
                }
                &.current {
                    background-color: #f7f8ff !important;
                }
            }
        }
    }
}

tr:hover,
tr:hover td {
    box-shadow: none !important;
    // background-color: $pink-bg-hover-color !important;
}

.table {
    td {
        border-top: none !important;
    }
}

@media (max-width: 1370px) {
    .dataTable {
        .dataTables_length {
            left: 0;
        }
        .dataTables_info {
            padding-bottom: 30px;
        }
    }
}

@media (max-width: 767px) {
    .dataTable {
        .dataTables_length {
            bottom: -35px;
        }
        .dataTables_info {
            padding-bottom: 0;
        }
    }
}
