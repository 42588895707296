@import "./variables";

.form-control {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  outline: none;
  background: white !important;
  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $border-color;
  }
  &:disabled {
    background: white !important;
    //color: $disabled-text-color !important;
    color: $grey-text-color !important;
  }
  &.multiline {
    height: auto;
  }
}

.success-mark,
.error-mark {
  background-color: #bece08;
  padding: 5px 8px;
  border-radius: 50%;
  color: white;
  margin-left: 10px;
}

.success-mark {
  &.big {
    padding: 20px 24px;
  }
}

.error-mark {
  background-color: $error-color;
  padding: 5px 10px;
}

.mat-form-field {
  margin-top: 40px;
  border: 1px solid $border-color;
  border-radius: 3px;
  &.green-border {
    border: 1px solid $green-color;
  }
  &.ng-invalid.ng-touched {
    border: 1px solid $error-color;
  }
  &.ng-valid.ng-touched:not(.distribution-input) {
    border: 1px solid $green-color;
  }
  .success-mark,
  .error-mark {
    position: absolute;
    top: 10px;
    left: 100%;
  }
  .mat-error {
    display: block;
    position: absolute;
    bottom: 18px;
    left: 102%;
    color: $error-color;
    font-size: 13px;
    font-style: italic;
  }
}

.form-control.is-invalid {
  background-position: right 0 center;
}

.text-green-form-control .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  .mat-input-element {
    color: $green-color !important;
    &:disabled {
      color: $green-color !important;
    }
  }
}

.text-red-form-control .mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  .mat-input-element {
    color: $error-color !important;
    &:disabled {
      color: $error-color !important;
    }
  }
}

.wtihManualError {
  .form-group {
    .mat-form-field {
      border: 1px solid $error-color !important;
      .success-mark {
        display: none;
      }
    }
  }
}

.error-new-row {
  .mat-checkbox-layout {
    white-space: inherit;
  }
  .mat-form-field {
    .mat-error {
      position: inherit;
      white-space: break-spaces;
      line-height: 1;
    }
  }
}

.mat-autocomplete-panel.mat-autocomplete-visible {
  visibility: visible;
  box-shadow: 0 2px 6px 0 #00000028 !important;
  background: #fff !important;
  border-radius: 4px !important;
  .mat-option {
    &:hover {
      background-color: $color-option-bg-hover;
    }
  }
}

.form-group.mobile {
  .mat-form-field-label {
    //transform: translateY(-80px) translateX(-44px) scale(1) !important;
    transform: translateY(-60px) translateX(-34px) scale(1) !important;
  }
}

.form-control-sm {
  min-height: auto;
}

.m-0 {
  .form-group {
    &.inline {
      margin: 0;
    }
  }
}

.form-group {
  &.disabled {
    .mat-form-field-required-marker {
      display: none;
    }
  }
  &.withTooltip {
    &.selection-list {
      .mat-list-text {
        display: inline-block;
        .value-text {
          display: inline-block;
        }
        .icon {
          display: inline-block;
          position: inherit;
          margin-left: 7px;
        }
      }
    }
  }
  &.compact {
    margin-top: 0;
    .mat-selection-list {
      padding-top: 0;
      position: initial;
    }
  }
  &.with-postfix {
    .mat-form-field-suffix {
      padding-right: 5px !important;
      color: $disabled-text-color;
    }
  }
  &.inline {
    // margin: 10px;
    .mat-form-field {
      margin-top: 0;
      height: auto;
      display: inline-block !important;
      .mat-form-field-wrapper {
        height: auto;
        .mat-form-field-flex {
          height: auto;
          border-radius: 3px;
          .mat-form-field-infix {
            .mat-input-element {
              padding: 10px;
            }
            .mat-select {
              padding: 10px;
              min-width: 50px;
            }
          }
        }
      }
    }
    &.datepicker {
      display: inline-block !important;
      .mat-button-wrapper {
        position: absolute;
        bottom: 3px;
        right: -12px;
      }
    }
    &.selection-list {
      margin-top: 19px;
      mat-label {
        font-weight: 600;
      }
      .mat-selection-list.mat-list-base {
        padding-top: 0px;
        .mat-list-item {
          display: inline-block;
          padding-right: 20px;
        }
        &.ng-invalid {
          .mat-pseudo-checkbox {
            border: 1px solid $error-color !important;
          }
        }
      }
    }
    .inline-form-control-title {
      font-weight: 600;
      margin-right: 15px;
    }
    .mat-select.form-control {
      border: none !important;
    }
    .editor {
      .ng-invalid {
        .angular-editor-textarea {
          border: 1px solid $error-color !important;
        }
      }
    }
    .mat-checkbox {
      .mat-checkbox-layout {
        margin-bottom: 0;
      }
    }
  }
}

// .mat-form-field-required-marker {
//   display: none !important;
// }

.hideRequiredMarker {
  .mat-form-field-required-marker {
    display: none !important;
  }
}

.phone {
  .mat-form-field-label {
    transform: translateX(-33px) translateY(-60px) scale(1) !important;
  }
}

.no-required-marker {
  .mat-form-field-required-marker {
    display: none !important;
  }
}

.no-title {
  .mat-form-field-required-marker {
    display: none !important;
  }
  &.two-lines-xl {
    .form-group {
      margin-top: 0 !important;
      .mat-form-field {
        margin-top: 21px;
      }
    }
  }
  &.mt-0 {
    .form-group {
      margin-top: 0 !important;
      .mat-form-field {
        margin-top: 0;
      }
    }
  }
  &.mt-2 {
    .form-group {
      margin-top: 0.5rem !important;
    }
  }
  .form-group {
    margin-top: -1rem !important;
    .mat-form-field {
      margin-top: 18px;
    }
  }
  &.in-table-cell {
    .mat-form-field {
      margin-top: 25px;
      margin-bottom: 7px;
    }
  }
}

.common-title-label {
  font-weight: 600;
  margin-top: 18px;
}

@media (max-width: 767px) {
  .like-no-title-in-mobile {
    margin-top: -1rem !important;
    .mat-form-field {
      margin-top: 18px;
    }
  }
}

@media (max-width: $xl-size) {
  .no-title {
    &.two-lines-xl {
      .form-group {
        margin-top: auto;
        .mat-form-field {
          margin-top: auto;
        }
      }
    }
  }
}
