@import './variables';

.container {
  max-width: 100%;
  .page-body {
    padding: 0px 10px;
  }
}

b,
strong {
  font-weight: 600;
}

.flex {
  display: flex;
}

.mw-300 {
  min-width: 300px;
}
.mw-200 {
  min-width: 200px;
}

.card {
  text-align: left;
  margin: 15px 0;
  border-color: $border-color;
  .card-title {
    border-bottom: 1px solid $line-color;
  }
  .card-text {
    margin-bottom: 0.5rem;
  }
  &.note {
    padding: 10px;
    .card-title {
      border-bottom: none;
    }
    .card-text {
      background-color: $background-color;
      border-radius: 0.25rem;
      padding: 5px 10px;
      border: 1px solid $border-color;
      color: $disabled-text-color;
    }
  }
}

.page-title {
  margin: 15px 0 0px 0;
  .button-bar {
    text-align: right;
    .btn {
      margin: 0 5px;
    }
  }
}

.pointer {
  cursor: pointer;
}

.help-block {
  color: $help-block-text-color;
  padding-left: 5px;
}

.form-control.is-invalid {
  border-color: transparent !important;
}

.ml-3 {
  margin-left: 0.75rem;
}

.lh1-7 {
  line-height: 1.7rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.sidenav-content {
  padding: 5px 15px;
  margin-bottom: 120px;
}

.condition {
  padding: 10px 10px;
  font-size: 14px;
  line-height: 18px;
  background-color: white;
  margin: 5px 0 15px 0;
  color: $disabled-text-color;
  border: 1px solid $border-color;
  .close {
    background: url('../img/close-icon.png') no-repeat;
    width: 14px;
    height: 14px;
    float: right;
    margin-top: 3px;
    cursor: pointer;
  }
}

.div-to-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.mat-drawer-content {
  position: absolute;
  width: calc(100% - 73px);
  height: 100%;
  margin-left: 73px !important;
}

.inline-block {
  display: inline-block;
}
