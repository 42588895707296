@import "./variables";

.mat-expansion-panel {
    border: 1px solid $border-color;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.25rem;

    &.mat-expansion-panel-spacing {
        margin: 0;
    }
    .mat-expansion-panel-header {
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        .mat-expansion-indicator {
            &::after {
                color: $disabled-text-color;
            }
        }
    }
    .mat-expansion-panel-content {
        .mat-expansion-panel-body {
            border-top: 1px solid $border-color;
            padding: 0.5rem 1rem;
        }
    }
}
