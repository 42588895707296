@import "variables";

.info-icon {
    width: 46px;
    height: 46px;
    display: inline-block;
    background: $light-pink-color;
    border-radius: 23px;
    img {
        display: block;
        margin: 14px auto;
        height: 18px;
    }
}

.inline-tooltip {
    .icon {
        display: inline-block !important;
        position: inherit !important;
        margin-left: 7px !important;
    }
}

.icon-in-description {
    height: 14px;
}

.disabled-icon {
    pointer-events: none;
    opacity: 0.6;
}

.no-pointer-events {
    pointer-events: none;
}

.btn.with-icon {
    padding: 7px 15px;
    .icon {
        &.small-width {
            padding-right: 7px;
            margin-right: 8px;
        }
        padding-right: 20px;
        background-position: right;
        background-repeat: no-repeat;
        margin-right: 8px;
        background-size: contain;
        &.back {
            background-image: url("../img/back-icon.svg");
        }
        &.edit {
            background-image: url("../img/table-edit-icon.svg");
        }
        &.rdv {
            background-image: url("../img/rdv-icon.svg");
        }
        &.devis {
            background-image: url("../img/devis-icon.png");
            background-size: auto;
        }
        &.leter {
            background-image: url("../img/lettre-icon.png");
            background-size: auto;
        }
        &.plus-filled-circle {
            background-image: url("../img/plus-purple-filled-circle.svg");
        }
    }
}

.icon {
    padding-right: 21px;
    background-position: right;
    background-repeat: no-repeat;
    margin-right: 3px;
    cursor: pointer;
    &.disabled {
        opacity: 0.4;
        cursor: inherit;
        pointer-events: none;
    }
    &.red {
        filter: grayscale(100%) brightness(100%) sepia(100%) hue-rotate(-50deg) saturate(1000%) contrast(1);
    }
    &.star {
        background-image: url("../img/star-icon.png");
    }
    &.star-filled {
        background-image: url("../img/star-filled-icon.png");
    }
    &.edit {
        background-image: url("../img/edit-icon.svg");
    }
    &.return {
        background-image: url("../img/return-icon.svg");
    }
    &.send {
        background-image: url("../img/table-send-icon.png");
        padding-right: 25px;
    }
    &.upload {
        background-image: url("../img/upload-icon.svg");
    }
    &.delete {
        background-image: url("../img/table-col-delete-icon.png");
    }
    &.delete-blue {
        background-image: url("../img/delete-blue.svg");
    }
    &.btn-plus-icon {
        padding-right: 24px;
        background-image: url("../img/btn-plus-icon.svg");
    }
}
