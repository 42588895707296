@import "./variables";

$color-cb-bg: #192342;

.card {
  &.disabled {
    .mat-combobox {
      &.disabled {
        mat-label,
        label::after,
        .mat-radio-container {
          opacity: 1 !important;
        }
      }
    }
  }
}

.mat-combobox {
  &.disabled {
    mat-label,
    label::after,
    .mat-radio-container {
      //@include color-form-control-disabled;
    }
  }

  &:not(.disabled) {
    &:hover {
      mat-label {
        color: $text-color;
        opacity: 1 !important;
      }
    }
  }

  mat-label {
    // @include label-reqular;
    // @include font-normal;
  }
}

.vertical-combobox {
  max-height: 500px;
  overflow: auto;
  .select,
  .date-time {
    // @include font-normal;
    // @include value-reqular;
    font-weight: 800;
  }
  .date-time {
    margin-left: 18px;
  }
  .select {
    margin-left: 38px;
  }
  .mat-combobox {
    .mat-radio-button {
      display: block;
      margin: 2px 0 2px 40px;
      .mat-radio-label-content {
        margin-left: 30px;
      }
    }
  }
}
