@import "./variables";

.in-col {
    .mat-checkbox {
        margin-top: 20px;
    }
}

.in-middle {
    .mat-checkbox {
        .mat-checkbox-layout {
            margin-top: 43px;
        }
    }
}

.big-title {
    .mat-checkbox {
        .mat-checkbox-label {
            font-weight: 600;
            font-size: 1rem;
        }
    }
}

.mat-checkbox {
    margin-top: 2px;
    &:not(.mat-checkbox-disabled),
    &:not(.mat-checkbox-transparent) {
        &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
        &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
            background-color: $pink-color;
        }
    }
    &.mat-checkbox-disabled {
        // @include color-form-control-label-disabled;
        .mat-checkbox-frame {
            // @include color-form-control-label-disabled;
        }
        .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
        .mat-checkbox-checked.mat-accent .mat-checkbox-background {
            background-color: none !important;
        }
    }
    label {
        width: auto;
    }
    .mat-checkbox-inner-container {
        height: 20px !important;
        width: 20px !important;
        .mat-checkbox-input {
            display: none;
        }
        .mat-checkbox-ripple {
            display: none !important;
        }
        .mat-checkbox-background {
            border-radius: 4px;
        }
        .mat-checkbox-frame {
            background-color: white;
            border-radius: 2px;
            border: 1px solid $border-color;
        }
    }
    .mat-checkbox-label {
        font-size: 13px;
        padding-top: 3px;
        line-height: normal;
        color: $text-color;
    }
}

.selection-list {
    .success-mark,
    .checkbox-error {
        position: absolute;
        top: 60px;
    }
    .checkbox-error {
        right: 20%;
        color: red;
        font-size: 13px;
        font-style: italic;
    }
    .input-inside-checkbox-list {
        position: relative;
        top: -60px;
        right: -30%;
    }
}

.mat-selection-list.mat-list-base {
    padding: 0;
    padding-top: 10px;
    .mat-list-item {
        height: 37px;
        width: auto;
        display: table;
        .mat-list-item-content {
            &.mat-list-item-content-reverse {
                padding: 0;
                flex-direction: initial;
                justify-content: flex-start;
            }
            .mat-pseudo-checkbox {
                width: 20px;
                height: 20px;
                border: 1px solid $border-color;
                &.mat-pseudo-checkbox-checked {
                    border: 1px solid $border-color;
                    &:after {
                        top: 5.2px;
                        left: 3.2px;
                        width: 9px;
                        color: $text-color;
                    }
                }
            }
            .mat-list-text {
                font-size: 13px;
                line-height: 22px;
                color: $text-color;
                padding-right: 0 !important;
                padding-left: 8px !important;
            }
        }
    }
}

.checkbox-custom {
    .mat-checkbox-label {
        font-size: 20px;
        line-height: 22px;
        margin-top: 0;
        color: $text-color;
        font-weight: 600;
    }
}

.big-text {
    .mat-checkbox-layout {
        white-space: inherit;
        .mat-checkbox-inner-container {
            margin-top: 4px;
        }
    }
}

.break-spaces {
    .mat-checkbox-label {
        white-space: break-spaces;
    }
}

.mat-checkbox {
    &:not(.mat-checkbox-disabled),
    &:not(.mat-checkbox-transparent) {
        &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
        &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
            background-color: transparent;
            transform: scale(0.8);
        }
    }
}

.mat-checkbox-checkmark-path {
    stroke: black !important;
}

.missionType {
    .mat-list-item {
        width: 33% !important;
        padding-right: 0px !important;
    }
}

@media (max-width: 767px) {
    .big-text {
        .mat-checkbox-layout {
            width: 100%;
        }
    }

    .missionType {
        .mat-list-item {
            width: 100% !important;
        }
    }

    .in-middle {
        .mat-checkbox {
            .mat-checkbox-layout {
                margin-top: 20px;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .missionType {
        .mat-list-item {
            width: 100% !important;
        }
    }
}

@media (min-width: 992px) and (max-width: 1400px) {
    .missionType {
        .mat-list-item {
            width: 50% !important;
        }
    }
}
