/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */

@import "~bootstrap/scss/bootstrap";
@import "~@angular/material/theming";
@import "~@angular/cdk/overlay-prebuilt.css";
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/scss/font";
@import "./assets/scss/layout";
@import "./assets/scss/menu";
@import "./assets/scss/icons";
@import "./assets/scss/inputs";
@import "./assets/scss/card";
@import "./assets/scss/checkbox";
@import "./assets/scss/dropdown";
@import "./assets/scss/form-control";
@import "./assets/scss/mat-form-field";
@import "./assets/scss/tooltip";
@import "./assets/scss/buttons";
@import "./assets/scss/datepicker";
@import "./assets/scss/toogle";
@import "./assets/scss/expansion-panel";
@import "./assets/scss/combobox";
@import "./assets/scss/table";
@import "./assets/scss/data-table";
@import "./assets/scss/file-uploader";
@import "./assets/scss/firefox";
@import "./assets/scss/responsive"; // should be the last
