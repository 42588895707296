@import "./variables";

.card {
    border: 1px solid $border-color;
    .card-header {
        background-color: white;
        border-bottom: 1px solid $border-color;
        h5 {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 767px) {
    .title-col {
        text-align: center;
        &.subsection {
            margin-top: 10px;
        }
    }
}
