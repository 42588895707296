@import "./variables";

thead,
tbody,
tfoot,
tr,
td,
th {
    font-weight: 100;
}

th {
    font-weight: 600;
    background-color: $background-color !important;
}

.table th,
.table td {
    border-top: none !important;
    vertical-align: middle !important;
}

tr {
    background: white;
}

.actions {
    img {
        height: 18px;
        cursor: pointer;
        padding: 0 5px;
    }
}

.table-hover tbody tr:hover {
    background-color: $color-option-bg-hover;
}

table {
    &.rounded {
        border-collapse: separate;
        border-spacing: 0;
        tr th,
        tr td {
            border-right: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            padding: 5px 10px;
        }

        tr th:first-child,
        tr td:first-child {
            border-left: 1px solid $border-color;
        }
        tr th:first-child,
        tr td:first-child {
            border-left: 1px solid $border-color;
        }
        tr th {
            background: #eee;
            text-align: left;
            border-top: solid 1px $border-color !important;
        }

        tr:first-child th:first-child {
            border-top-left-radius: 0.25rem;
        }

        tr:first-child th:last-child {
            border-top-right-radius: 0.25rem;
        }

        tr:last-child td:first-child {
            border-bottom-left-radius: 0.25rem;
        }

        tr:last-child td:last-child {
            border-bottom-right-radius: 0.25rem;
        }
    }
}

.rounded-box {
    border: 1px solid $border-color;
    border-radius: 0.25rem;
    padding: 10px 20px;
}

@media (max-width: 767px) {
}
