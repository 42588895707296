@import "./variables";

.pointer-events-none {
    pointer-events: none;
}

.btn {
    font-size: 14px;
    padding: 7px 20px;
    font-weight: 600;
    &:focus,
    &:active {
        outline: 0;
        box-shadow: none;
    }
    &.pink {
        border: 1px solid $pink-color;
        color: $pink-color;
        &:hover:not(:disabled) {
            background-color: #f7f8ff;
        }
        &:disabled {
            border: 1px solid $border-color;
            color: $text-color;
        }
    }
    &.red {
        border: 1px solid $error-color;
        color: $error-color;
        &:hover {
            background-color: #fff6f8;
        }
        &:disabled {
            border: 1px solid $border-color;
            color: $text-color;
        }
    }
    &.one-in-col {
        margin: 20px 0px;
    }
    img {
        height: 12px;
        padding-right: 3px;
    }
}

.icon-in-col {
    cursor: pointer;
    position: relative;
    top: 48px;
    display: block;
    height: 23px;
    &.red {
        filter: grayscale(100%) brightness(100%) sepia(100%) hue-rotate(-50deg) saturate(1000%) contrast(1);
    }
}

.submitCancel {
    margin-top: 20px;
    .text-end {
        text-align: right;
    }
    .btn {
        margin: 0 10px;
        background-color: white;
    }
}

@media (max-width: 767px) {
    .submitCancel {
        .col {
            text-align: center !important;
        }
    }
}

button.disabled {
    pointer-events: none;
}
