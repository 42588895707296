@import './variables';

$color-option-bg: #fff;
$color-option-bg-hover: #f5f6f7;
$color-option-bg-active: #eeeff2;
$error-message-font-size: 11px;

.mat-input-element {
  box-sizing: border-box !important;
}

.w120 {
  .mat-form-field-infix {
    width: 120px;
  }
}

.w100perc {
  .mat-form-field-infix {
    width: 100%;
  }
}

.mat-form-field {
  display: block !important;
  height: 40px;
  background: white;
  &.multiline {
    height: auto;
    .mat-form-field-wrapper {
      height: auto;
      .mat-form-field-flex {
        height: auto;
        .mat-form-field-infix {
          display: inline-table;
          textarea {
            margin-bottom: 0px;
          }
          .mat-form-field-label-wrapper {
            .mat-form-field-label {
              transform: none;
              position: absolute;
              top: -22px;
            }
          }
        }
      }
    }
  }

  .mat-form-field-wrapper {
    height: 40px;
    .mat-form-field-subscript-wrapper {
      padding: 0;
      margin-top: 3px;
      //font-size: $error-message-font-size;
      //overflow: visible;
      white-space: nowrap;
    }
    .mat-form-field-flex {
      height: 40px;
      padding: 0;
      background: none !important;
      .mat-form-field-infix {
        height: 100%;
        padding: 0;
        //border-top: none;
        .form-control {
          //border: none;
          box-shadow: none;
          &.multiline {
            height: auto;
          }
        }
        .mat-select {
          .mat-select-trigger {
            .mat-select-arrow-wrapper {
              transform: translateY(0%);
            }
          }
        }
        .mat-form-field-label-wrapper {
          position: initial;
          .mat-form-field-label {
            //overflow: visible;
            transform: translateY(-60px) scale(1);
            font-weight: 600;
          }
        }
        .mat-input-element,
        .mat-select {
          padding: 9px 10px;
          font-size: 14px;
          line-height: 19px;
          margin-top: 0px;
          color: $form-control-text-color;
          height: auto;
          &:disabled {
            // @include color-form-control-disabled;
          }
        }
        .mat-select-empty {
          color: $disabled-text-color;
        }
      }
    }
    .mat-form-field-underline {
      display: none;
      &:before {
        // background-color: $color-border-form-control;
      }
      .mat-form-field-ripple {
        opacity: 0 !important;
        background-color: none !important;
      }
    }
  }
  &.ng-invalid.ng-touched {
    .mat-select-arrow-wrapper {
      // color: $error-color !important;
    }
    .mat-select-value {
      // color: $error-color !important;
    }
    .mat-form-field-underline {
      display: none;
      &:before {
        background-color: $error-color !important;
      }
    }
  }
  &.multiline {
    .mat-form-field-underline {
      &:before {
        display: none;
      }
    }
  }
}

.mat-select-panel {
  position: absolute;
  top: 34px;
  left: -4px;
  max-width: fit-content !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 6px 0 #00000028 !important;
  background: #fff !important;
  .mat-option {
    margin: 0;
    padding: 0 20px;
    line-height: 24px !important;
    height: 24px !important;
    &:hover {
      background-color: $color-option-bg-hover;
    }
    &.auto-height {
      height: auto !important;
      white-space: inherit;
    }
    &.mat-selected {
      &:not(.mat-option-disabled) {
        background-color: $color-option-bg-active;
        // @include option-reqular;
      }
    }
    &.mat-active {
      background-color: $color-option-bg-hover;
    }
  }
}

.mat-autocomplete-panel {
  .mat-option {
    &.auto-height {
      height: auto !important;
      white-space: inherit;
    }
    &.mat-active {
      background-color: $color-option-bg-hover;
    }
  }
}

form.readonly {
  .photo-buttons-row {
    display: none;
  }
}

.mat-form-field.readonly,
form.readonly .mat-form-field {
  .mat-form-field-wrapper {
    .mat-form-field-underline {
      &:before {
        display: none;
      }
    }
  }
}

.mat-form-field.with-prefix {
  &.ng-untouched:not(.ng-dirty):not(.mat-focused) {
    .phone-prefix {
      color: $disabled-text-color;
    }
  }
  .phone-prefix {
    font-size: 14px;
    line-height: 18px;
    color: $form-control-text-color;
    padding-left: 8px;
    padding-right: 1px;
  }
  .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element {
    padding-left: 0;
  }
}

.cdk-overlay-container {
  z-index: 1051 !important;
}

.mat-input-element::placeholder {
  color: $disabled-text-color;
}

.mat-select.form-control.mat-select-disabled {
  // background: $disabled-input-background !important;
  // color: gray !important;
}

.separated-title {
  width: 100% !important;
  padding-left: 15px;
}
