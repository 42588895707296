@import "./variables";

$color-cb-bg: #192342;
$middle-grey: #808080;

.mat-combobox {
  line-height: 0;
  .mat-radio-group {
    &.ng-invalid.ng-touched {
      .mat-radio-button {
        &.selected-item {
          .mat-radio-label {
            background: $error-color;
          }
        }
        .mat-radio-label {
          background: $error-color;
        }
      }
    }
    .mat-radio-button {
      &.mat-radio-disabled {
        .mat-radio-label {
          border: 1px solid $pink-color;
          background: $disabled-background;
          color: $middle-grey;
          opacity: 0.65;
        }
      }
      &.selected-item {
        .mat-radio-label {
          background: $pink-color;
          color: white;
          .icon {
            filter: brightness(0) invert(1);
          }
        }
        &:focus {
          .mat-radio-label {
            background: $pink-color;
          }
        }
      }
      &:not(:first-child) {
        margin-left: 10px;
      }
      .mat-radio-label {
        background-color: white;
        color: $pink-color;
        border-radius: 0.25rem;
        transition: none;
        font-size: 14px;
        cursor: pointer;
        vertical-align: middle;
        padding: 4px 20px 13px 20px;
        position: relative;
        border: 1px solid $pink-color;
        text-align: center;
        display: inline-block;
        input[type="radio"] {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
        &:focus {
          box-shadow: none;
        }
        .mat-radio-container {
          width: 0;
          .mat-radio-outer-circle {
            display: none;
          }
        }
        .mat-radio-label-content {
          padding-left: 0;
          .icon {
            display: inline-block;
            margin: 0px 0px 0px 6px;
            height: 23px;
            top: 7px;
            position: absolute;
          }
        }
      }
    }
  }
}

.mat-combobox {
  &.disabled {
    .mat-radio-button {
      &.selected-item {
        .mat-radio-label {
          background: $pink-color;
          color: white;
          cursor: default;
        }
      }
      .mat-radio-label {
        background: $disabled-background;
        color: $grey-text-color;
        cursor: default;
      }
    }
  }

  &:not(.disabled) {
    &:hover {
      mat-label {
        //@include label-hover;
      }
    }
  }

  mat-label {
    // @include label-reqular;
    // @include font-normal;
  }
}

.vertical-combobox {
  max-height: 500px;
  overflow: auto;
  .select,
  .date-time {
    // @include font-normal;
    // @include value-reqular;
    font-weight: 800;
  }
  .date-time {
    margin-left: 18px;
  }
  .select {
    margin-left: 38px;
  }
  .mat-combobox {
    .mat-radio-button {
      display: block;
      margin: 2px 0 2px 40px;
      .mat-radio-label-content {
        margin-left: 30px;
      }
    }
  }
}

.mat-radio-buttons-wrapper {
  display: inline-block;
}
