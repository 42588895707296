@import './variables';

.btn {
  font-size: 14px;
  padding: 7px 20px;
  font-weight: 600;
  background: white;
  margin-right: 0.75rem;
  &:focus,
  &:active {
    outline: 0;
    box-shadow: none;
  }
  &.pink {
    border: 1px solid $pink-color;
    color: $pink-color;
    &:hover {
      background-color: $pink-bg-hover-color;
    }
    &:disabled {
      border: 1px solid $border-color;
      color: $text-color;
      background: none;
    }
    &.fill {
      background-color: $pink-color;
      color: white;
      &:hover {
        background-color: $pink-hover-color;
      }
      &:disabled {
        border: 1px solid $border-color;
      }
    }
  }
  &.gray {
    border: 1px solid $border-color;
    color: $text-color;
    &:hover {
      background-color: #fcfcfc;
    }
    &:disabled {
      border: 1px solid $border-color;
      color: $text-color;
    }
  }
  &.red {
    border: 1px solid $error-color;
    color: $error-color;
    &:hover {
      background-color: #fff6f8;
    }
    &:disabled {
      border: 1px solid $border-color;
      color: $text-color;
    }
  }
  &.one-in-col {
    margin: 20px 0px;
  }
  img {
    height: 12px;
    padding-right: 3px;
  }
  .spinner-border {
    width: 1rem !important;
    height: 1rem !important;
  }
}

.btn-note-close {
  font-size: 24px;
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}

.btn-note-edit {
  position: absolute;
  right: 30px;
  top: 12px;
  width: 14px;
  height: 14px;
  background-size: contain;
}
