/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */

@import "~bootstrap/scss/bootstrap";
@import "~@angular/material/theming";
@import "~@angular/cdk/overlay-prebuilt.css";
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "./assets/scss/layout";
@import "./assets/scss/inputs";
@import "./assets/scss/buttons";
@import "./assets/scss/form-control";
@import "./assets/scss/dropdown";
@import "./assets/scss/mat-form-field";
@import "./assets/scss/font";
@import "./assets/scss/modal";
@import "./assets/scss/form-controls";
@import "./assets/scss/horizontal-menu";
@import "./assets/scss/card";

@import "./assets/scss/responsive"; // should be the last
