@import './variables';

hr {
  background-color: $border-color;
  opacity: 1;
}

.separator {
  border-right: 1px solid $border-color;
}

.disabled {
  pointer-events: none;
  cursor: inherit;
  opacity: 0.6;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}
.w120px {
  width: 120px;
}

.fw-600 {
  font-weight: 600 !important;
}

.p-0 {
  padding: 0;
}

.mt-6 {
  margin-top: 2.5rem !important;
}

.mt-10 {
  margin-top: 5rem !important;
}

.top-align {
  vertical-align: top;
}

.text-center {
  text-align: center !important;
}

.ai-center {
  align-items: center;
}

.title-with-del {
  margin-top: 20px;
  h5,
  h6 {
    display: inline-block;
  }
  img {
    display: inline-block;
    margin-top: -6px;
    margin-left: 10px;
    cursor: pointer;
    position: relative;
    height: 20px;
    filter: grayscale(100%) brightness(100%) sepia(100%) hue-rotate(-50deg) saturate(1000%) contrast(1);
  }
}

.empty-col {
  height: 80px;
}

.modes {
  div[class^='mode'],
  div[class*=' mode'] {
    text-align: center;
    display: inline-block;
    font-weight: 600;
    border-left: none;
    cursor: pointer;
  }
  .mode {
    width: 45%;
    padding: 10px;
    color: $disabled-text-color;
    &.active {
      width: 55%;
      background-color: $border-color;
      color: $pink-color;
    }
  }
  .mode-filter {
    width: 20%;
    padding: 10px;
    color: $pink-color;
    border: 1px solid $border-color;
    background-color: white;
    &:first-of-type {
      border-left: 1px solid $border-color;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-of-type {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    &.active {
      background-color: $pink-color;
      color: white;
    }
  }
  .mode-site {
    padding: 7px;
    color: $pink-color;
    border: 1px solid $border-color;
    background-color: white;
    &:first-of-type {
      border-left: 1px solid $border-color;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-of-type {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    &.active {
      background-color: $pink-color;
      color: white;
    }
  }
  .mode-devis {
    min-width: 140px;
    padding: 7px;
    color: $disabled-text-color;
    border: 1px solid $border-color;
    &:first-of-type {
      border-left: 1px solid $border-color;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-of-type {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    &.active {
      background-color: $border-color;
      color: $pink-color;
    }
  }
  .mode-table-template {
    text-align: left !important;
    margin: 0 0 5px 0;
    width: 100%;
    padding: 7px;
    color: $disabled-text-color;
    border: 1px solid $border-color;
    border-left: 1px solid $border-color !important;
    &:first-of-type {
      border-left: 1px solid $border-color;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    &:last-of-type {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
    &.active {
      padding: 0px 2px 2px 2px;
      // background-color: white;
      // color: $pink-color;
      // padding: 0;
    }
    &.btn {
      text-align: center !important;
    }
  }
}

.action-column {
  width: 98px !important;
}

.text-end {
  text-align: right;
}

.buttons-in-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  &.start {
    justify-content: flex-start;
  }
}

.custom-row-padding {
  padding: 6px 0px;
}

.custom-td-padding {
  padding: 11px 10px !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .empty-col {
    height: 0px;
  }
  .modes {
    .mode-filter {
      width: 50%;
    }
  }
}
