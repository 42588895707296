@import './variables';

.control-label {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: start;
  &.top {
    align-items: start;
    padding-top: 6px;
  }
  &.left {
    justify-content: start;
    padding-left: 20px;
  }
  &.with-small {
    flex-direction: column;
    align-items: flex-start;
    .small-text {
      font-size: 11px;
      color: $disabled-text-color;
    }
  }
  &.no-bold {
    font-weight: normal;
  }
}

.fw-600 {
  font-weight: 600;
}

.control-col {
  display: flex;
  .w100perc {
    width: 100%;
    .form-group {
      width: 100%;
      &.inline {
        margin: 0;
      }
      .inline-form-control-title {
        margin-right: 0;
      }
    }
  }
}

.mb-0-fg {
  .form-group {
    margin-bottom: 0;
  }
}

.w70 {
  .form-group {
    &.inline {
      .mat-form-field {
        width: 70px;
      }
    }
  }
}

.w100px {
  .form-group {
    &.inline {
      .mat-form-field {
        width: 100px;
        .mat-select {
          padding-right: 4px !important;
        }
      }
    }
  }
}

.form-group {
  &.compact {
    margin-top: 0;
    .mat-selection-list {
      padding-top: 0;
    }
  }
  &.inline {
    margin: 5px;
    margin-left: 0px;
    display: block;
    .mat-form-field {
      margin-top: 0;
      height: auto;
      width: 100%;
      .mat-form-field-wrapper {
        height: auto;
        .mat-form-field-flex {
          height: auto;
          border-radius: 3px;
          .mat-form-field-infix {
            .mat-input-element {
              // padding: 5px;
            }
            .mat-select {
              // padding: 5px;
            }
          }
        }
      }
    }
    &.datepicker {
      display: inline-block;
      .mat-button-wrapper {
        position: absolute;
        bottom: 3px;
        right: -12px;
      }
    }
    &.selection-list {
      .mat-selection-list.mat-list-base {
        padding-top: 0px;
        .mat-list-item {
          display: inline-block;
          padding-right: 20px;
        }
        &.ng-invalid {
          .mat-pseudo-checkbox {
            border: 1px solid $error-color !important;
          }
        }
      }
    }
    .mat-select.form-control {
      border: none !important;
    }
    .editor {
      .ng-invalid {
        .angular-editor-textarea {
          border: 1px solid $error-color !important;
        }
      }
    }
    .mat-checkbox {
      .mat-checkbox-layout {
        margin-bottom: 0;
      }
    }
  }
}

.form-control-in-title {
  float: right;
  .form-group {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .control-label {
    text-align: left;
    justify-content: start;
  }
}
