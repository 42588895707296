@import "./variables";

.nav-item {
    &.inactive {
        pointer-events: none;
        opacity: 0.8;
    }
}

.example-container {
    border-top: 1px solid $input-border;
}

.example-sidenav {
    user-select: none;
}

.menu-button {
    transition: 300ms ease-in-out;
    transform: rotate(0deg);
}

.menu-button.rotated {
    transform: rotate(180deg);
}

.submenu {
    display: none;
    overflow-y: hidden;
    transform-origin: top;
    padding-left: 10px;
    &.expanded {
        display: block;
    }
}

.mat-toolbar-row,
.mat-toolbar-single-row {
    height: 50px !important;
}

mat-sidenav,
mat-nav-list,
mat-list-item,
mat-sidenav-container,
mat-sidenav-content {
    transition: all 0s ease 0s !important;
}

mat-list-item {
    &:hover {
        color: $pink-color;
    }
    &.active-link {
        color: $pink-color;
        &:hover {
            color: $pink-color;
        }
    }
}

.mat-sidenav-content {
    background-color: $background-color;
}

mat-sidenav-container {
    position: fixed !important;
    // height: calc(100% - 50px) !important;
    // min-height: calc(100% - 50px) !important;
    height: 100% !important;
    min-height: 100% !important;
    width: 100%;
    min-width: 100%;
}

.mat-drawer {
    border-right: 1px solid $input-border;
    &.mat-drawer-side {
        background-color: white;
    }
}

button {
    outline: none;
    border: 1px solid $input-border;
}

mat-expansion-panel-header {
    height: 48px !important;
}

.full-width {
    display: none;
    width: 100%;
    &.visible {
        display: inline-block;
        padding-right: 30px;
    }
}

.menu-icon {
    height: 22px;
    width: 22px;
    flex-shrink: 0;
    margin-left: 10px;
    padding-right: 30px;
    &.clients {
        background: url("../img/clients-icon.svg") no-repeat;
    }
    &.prospects {
        background: url("../img/prospects-icon.svg") no-repeat;
    }
    &.devis {
        background: url("../img/devis-icon.svg") no-repeat;
    }
    &.lettre-mission {
        background: url("../img/lettre-mission-icon.svg") no-repeat;
    }
    &.calendriers {
        background: url("../img/calendriers-icon.svg") no-repeat;
    }
    &.dashboards {
        background: url("../img/dashboards-icon.svg") no-repeat;
    }
    &.alerts {
        background: url("../img/alerts-icon.svg") no-repeat;
    }
    &.expand {
        width: 14px;
        height: 7px;
        position: absolute;
        right: 4px;
        margin-left: 0;
        padding-right: 20px;
        background: url("../img/expand.svg") no-repeat;
    }
}

.mat-list-item:hover,
.active-link {
    .menu-icon {
        &.clients {
            background: url("../img/clients-icon-active.svg") no-repeat;
        }
        &.prospects {
            background: url("../img/prospects-icon-active.svg") no-repeat;
        }
        &.devis {
            background: url("../img/devis-icon-active.svg") no-repeat;
        }
        &.lettre-mission {
            background: url("../img/lettre-mission-icon-active.svg") no-repeat;
        }
        &.calendriers {
            background: url("../img/calendriers-icon-active.svg") no-repeat;
        }
        &.dashboards {
            background: url("../img/dashboards-icon-active.svg") no-repeat;
        }
        &.alerts {
            background: url("../img/alerts-icon-active.svg") no-repeat;
        }
    }
}

.table-col-icon {
    height: 20px;
    width: 20px;
    display: inline-block;
    margin: 0 2px;
    background-size: contain !important;
    cursor: pointer;
    &.in-row {
        height: 14px;
        width: 15px;
    }
    &.cancel {
        background: url("../img/table-col-cancel-icon.png") no-repeat;
    }
    &.apply {
        background: url("../img/table-col-apply-icon.png") no-repeat;
        vertical-align: sub;
    }
    &.apply-purple {
        background: url("../img/table-col-apply-icon-purple.png") no-repeat;
        vertical-align: sub;
    }
    &.edit {
        background: url("../img/table-col-edit-icon.png") no-repeat;
    }
    &.delete {
        background: url("../img/table-col-delete-icon.png") no-repeat;
    }
    &.search {
        background: url("../img/search-icon-black.svg") no-repeat;
    }
    &.edit-pencil {
        background: url("../img/pencil-purple.svg") no-repeat;
    }
    &.delete-cross-purble {
        background: url("../img/delete-cross-purple.svg") no-repeat;
    }
    &.close-purple {
        background: url("../img/close-purple.svg") no-repeat;
    }
    &.plus-purple-empty {
        background: url("../img/plus-purple-empty.svg") no-repeat;
    }
    &.plus-purple-filled {
        background: url("../img/plus-purple-filled.svg") no-repeat;
    }
    &.plus-purple-filled-circle {
        background: url("../img/plus-purple-filled-circle.svg") no-repeat;
    }
    &.download {
        background: url("../img/download-default.svg") no-repeat;
    }
    &.download-purple {
        background: url("../img/download-purple.png") no-repeat;
    }
    &.search-purple {
        background: url("../img/search-purple.png") no-repeat;
    }
    &.send {
        background: url("../img/table-send-icon.png") no-repeat;
        vertical-align: sub;
    }
}

.parent.expanded {
    .menu-icon.expand {
        background: url("../img/collapse-active.svg") no-repeat;
    }
}

.three-dots-menu {
    .three-dots {
        display: inline-block;
        font-size: 30px;
        cursor: pointer;
        line-height: 22px;
        // transform: rotate(90deg);
        color: $menu-text-color;
    }

    .dropdown-content {
        display: none;
        border-radius: 0.25rem;
        position: absolute;
        margin-top: 0px;
        margin-left: -120px;
        background-color: white;
        min-width: 180px;
        overflow: auto;
        box-shadow: 0 2px 6px 0 #00000028 !important;
        z-index: 1;
        .menu-item {
            color: $pink-color;
            font-size: 13px;
            line-height: 24px !important;
            cursor: pointer;
            display: block;
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 3px 10px 3px 15px;
            &:hover {
                background-color: $pink-bg-hover-color;
            }
        }
        .menu-item-separator {
            display: block;
            margin: 0px 10px 0 15px;
            border-bottom: 1px solid $border-color;
        }
    }

    .show {
        display: block;
    }

    .table-menu-icon {
        display: inline-block;
        flex-shrink: 0;
        padding-right: 25px;
        height: 17px;
        width: 17px;
        background-size: contain !important;
        vertical-align: middle;
        &.calendar {
            background: url("../img/calendar-icon.svg") no-repeat;
        }
        &.details {
            background: url("../img/details_v2.svg") no-repeat;
            height: 13px;
        }
        &.edit {
            background: url("../img/table-edit-icon.svg") no-repeat;
        }
        &.send {
            background: url("../img/table-send-icon.png") no-repeat;
        }
        &.edit_v2 {
            background: url("../img/table-edit-icon-v2.png") no-repeat;
        }
        &.delete {
            background: url("../img/table-delete-icon.svg") no-repeat;
        }
        &.devis {
            background: url("../img/table-devis-icon.svg") no-repeat;
        }
        &.leter {
            background: url("../img/lettre-icon.png") no-repeat;
            background-size: inherit !important;
            height: 19px;
        }
        &.leter-closed {
            background: url("../img/closed-lettre-icon.png") no-repeat;
            height: 12px;
        }
        &.folder {
            background: url("../img/folder-icon.png") no-repeat;
            height: 13px;
        }
        &.download {
            background: url("../img/table-download-icon.svg") no-repeat;
        }
        &.search {
            background: url("../img/table-search-icon.svg") no-repeat;
        }
    }
}

.mat-list-base .mat-list-item .mat-list-item-content,
.mat-list-base .mat-list-option .mat-list-item-content {
}
