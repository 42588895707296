@import './variables';

.modal-header {
  padding: 0.25rem 1rem;
}

.btn-modal-close {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  color: white;
  background-color: $pink-color;
  text-align: center;
  position: absolute;
  right: -17px;
  top: -17px;
  cursor: pointer;
  &:hover {
    background-color: $pink-hover-color;
  }
  span {
    font-size: 32px;
    line-height: 28px;
  }
}

.mat-snack-bar-container {
  background-color: $border-color !important;
  border: 1px solid $border-color;
  .mat-button {
    border: 1px solid $border-color;
    background-color: white;
    color: black;
    &:hover {
      background-color: $pink-bg-hover-color;
    }
  }
}
