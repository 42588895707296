@import "./variables";

.menu-icon-white {
    height: 13px;
    margin-right: 8px;
    filter: invert(0.9);
}

.menu-icon-black {
    height: 13px;
    margin-right: 8px;
    filter: none;
}

.nav {
    padding-top: 3px;
    background-color: $background-color;
    &.nav-tabs {
        border-bottom: 1px solid $border-color;
        &.inactive-by-default {
            pointer-events: none;
        }
        &.can-activate-tabs {
            pointer-events: all;
        }
    }
}

.tabs-container {
    .tab-container {
        padding: 5px 10px;
    }
}

.nav-link {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    border: none !important;
    cursor: pointer;
    color: $menu-text-color;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;

    &:focus {
        outline: 0 none;
    }
}

.tabs li {
    /* Makes a horizontal row */
    float: left;
    text-decoration: none;
    outline: 0 none;
    /* So the psueudo elements can be
     abs. positioned inside */
    position: relative;
}
.tabs .nav-link {
    /* Make them block level
     and only as wide as they need */
    margin-bottom: 0px;
    float: left;
    padding: 6px 20px;
    text-decoration: none;
    outline: 0 none;

    /* Default colors */
    background: $background-color;

    /* Only round the top corners */
    -webkit-border-top-left-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-radius: 8px 8px 0 0;
    border-bottom: none;
}
.tabs .active {
    /* Highest, active tab is on top */
    z-index: 3;
}
.tabs .active .nav-link {
    /* Colors when tab is active */
    background-color: $pink-color;
    color: white;
    isolation: inherit;
    margin-bottom: 0px;
    .menu-icon-white {
        filter: none;
    }

    .menu-icon-black {
        filter: brightness(0) invert(1);
    }
}
.tabs .inActive {
    pointer-events: none;
    opacity: 0.8;
}
.tabs li:before,
.tabs li:after,
.tabs li .nav-link:before,
.tabs li .nav-link:after {
    /* All pseudo elements are 
     abs. positioned and on bottom */
    position: absolute;
    bottom: 0;
}
/* Only the first, last, and active
   tabs need pseudo elements at all */
.tabs li:last-child:after,
.tabs li:last-child .nav-link:after,
.tabs .active:after,
.tabs .active:before,
.tabs .active .nav-link:after,
.tabs .active .nav-link:before {
    content: "";
}

.tabs li:first-child:before,
.tabs li:first-child .nav-link:before,
.tabs li:last-child:after,
.tabs li:last-child .nav-link:after {
    content: none;
}

.tabs .active:before,
.tabs .active:after {
    background: $pink-color;

    /* Squares below circles */
    z-index: 1;
}
/* Squares */
.tabs li:before,
.tabs li:after {
    background: $background-color;
    width: 10px;
    height: 10px;
}
.tabs li:before {
    left: -10px;
}
.tabs li:after {
    right: -10px;
}
/* Circles */
.tabs li .nav-link:after,
.tabs li .nav-link:before {
    width: 20px;
    height: 20px;
    /* Circles are circular */
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: $background-color;

    /* Circles over squares */
    z-index: 2;
}
.tabs .active .nav-link:after,
.tabs .active .nav-link:before {
    background: $background-color;
}
/* First and last tabs have different
   outside color needs */
.tabs li:first-child.active .nav-link:before,
.tabs li:last-child.active .nav-link:after {
    background: $background-color;
}
.tabs li .nav-link:before {
    left: -20px;
}
.tabs li .nav-link:after {
    right: -20px;
}

@media (max-width: 767px) {
    .tabs .nav-link {
        padding: 6px 15px;
    }

    .menu-icon-white {
        margin-right: 0px;
    }

    .menu-icon-black {
        margin-right: 0px;
    }

    /* Squares */
    .tabs li:before,
    .tabs li:after {
        background: $background-color;
        width: 7px;
        height: 7px;
    }
    .tabs li:before {
        left: -3px;
    }
    .tabs li:after {
        right: -3px;
    }
    /* Circles */
    .tabs li .nav-link:after,
    .tabs li .nav-link:before {
        width: 14px;
        height: 14px;
        /* Circles are circular */
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        border-radius: 7px;
    }

    /* First and last tabs have different
   outside color needs */
    .tabs li .nav-link:before {
        left: -14px;
    }
    .tabs li .nav-link:after {
        right: -14px;
    }
}
